import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommentModel } from '../model/comment-model';
import { AbstractBaseListComponent } from '../../../../../components/base/abstract-base-list-component';
import { AclClassConstants } from '../../../../aclClassConstants';
import { AclConstants } from '../../../../aclOperationConstants';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { CommentService } from '../service/comment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SearchService } from '../../../../../services/search.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from '../../../../login/login.service';
import { IBaseService } from '../../../../../services/ibase-service';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../../../core/enum/searchType.enum';
import { OrderingData } from '../../../../../core/modal/ordering-data';
import { PaginationData } from '../../../../../core/modal/pagination-data';
import { SearchData } from '../../../../../core/modal/search-data';
@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent extends AbstractBaseListComponent<CommentModel> implements OnInit,AfterViewInit, OnDestroy {
  @Input() custId: any;
  @Output() docId: any = new EventEmitter();
  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild('serviceare', { static: false }) serviceAreaReff: TemplateRef<any>;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public searchData=[];
  public loginService: LoginService;
  dataGrid: CommentModel[] = [];
  // config for n-table
  dataSource = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  allColumns: any[] = [];
  public entity: CommentModel = new CommentModel();
  public entity_id: any = null;
  form: FormGroup;
  paginationData: any;
  neoTableSetting: any = {};
  totalRecords: any;
  filters = new Array<filters>()
  filtersObj = new filters();
  tableKey = TableKeyEnum;

  constructor(private entityService : CommentService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    loginService: LoginService) {
      super(toastrService,searchService);
      this.loginService = loginService;
    }

  ngOnInit() {
    this.setDefaultPageSize(this.tableKey.COMMENT_LIST)
    this.initForCaseList();
   
  }

  initForCaseList() {
    if (this.neoTableSetting && this.neoTableSetting.perPage) {
      this.paginationData.rowsPerPage = this.neoTableSetting.perPage;
    }
    this.orderingData = new OrderingData();
    this.paginationData = new PaginationData()
    this.neoTableSetting = this.getTableSetting();
    this.neoTableSearchSetting = this.getTableSearchSetting();
    let obj = {
      filterColumn: 'cust_id',
      filterCondition: null,
      filterValue: this.custId,
      filterDataType: null,
      filterOperator: null
    }
    this.searchData.push(obj);
    this.getList(this.paginationData, this.orderingData, this.searchData)
  }

  setFilterObj(value, filterName?) {
   
    this.filtersObj = new filters();
    this.filtersObj.filterColumn = 'cust_id';
    this.filtersObj.filterCondition = null;
    this.filtersObj.filterDataType = null;
    this.filtersObj.filterOperator = null;
    this.filtersObj.filterValue = this.custId;
    this.filters.push(this.filtersObj);

    this.searchService.removeCaseSpecificStatusColumn();
    this.onFilterChange(this.filters);
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
    });
  }

  getService(): IBaseService<CommentModel> {
    return this.entityService;
  }

  getTableColumn() {
    let self = this;

    let allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '0.5%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'text',
        default: 'Name',
        rowKey: 'text',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'lastModifiedByName',
        default: 'last Modified Name',
        rowKey: 'lastModifiedByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'updatedate',
        default: 'Last Modified',
        rowKey: 'updatedate',
        width: '10%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];
    this.allColumns = allColumns;
    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    return allColumns ? allColumns : [];
  }

  getTableSetting() {
    let commentNeoGridSettings = {
      showFooter: false,
      showPagination: true,
      tableId: this.tableKey.COMMENT_LIST,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      showFilter:false,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      serverSideSortPagination: true,
      pageTotalLength: 0,
      serverSideFilter: true,
      //defaultSortCol: '',
      //defaultSortDirection: 'desc',
      // enableMultiDelete: true,
      columns: this.getTableColumn(),
    };
    return commentNeoGridSettings;
  }

  getTableSearchSetting() {
    let searchSettings = [
      {
        display_field: 'Name',
        value_field: 'text',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      }
    ];
    return searchSettings;
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
    else{
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify([])));
    }
  }

  onSortChange(event) {
    let orderingData = new OrderingData();
    orderingData = event;
    this.onOrderingChange(orderingData);
  }

  onPageChange(event) {
    let paginationData = new PaginationData();
    paginationData = event;
    this.onPaginationChange(paginationData);
  }

  onEditClick(event) {
    ////console.log(event);
   // this.setUpdateValueInForm(event);
  }

  onDeleteClick(event) {
     if (event) {
       this.entityService.delete(event).subscribe((res)=>{
        if(res.responseCode===200){
          this.ngOnInit();
          this.toastrService.success('Comment has been deleted successfully.', 'Success');
        }
        else{
          this.ngOnInit();
          this.toastrService.error(res.responseMessage, 'Error');
        }
       })
     }
  }
  

  afterDeleteConfirm(objEvent) {
    if (objEvent.deleteClicked) {
      this.delete(objEvent.event);
    }
  }

  setList(list: Array<CommentModel>) {
    this.dataGrid = list;
    this.neoTableSetting.pageTotalLength = this.totalRecords;
    this.refreshDataSource(this.dataGrid);
  }

  applyFilter(filterObj) {
    
    let filteredDataForGrid = JSON.parse(JSON.stringify(this.dataGrid));
    if (filterObj && filterObj.filters) {

      filteredDataForGrid = this.searchService.getFilteredData(filterObj.filters, this.dataGrid);
    }
    this.refreshDataSource(filteredDataForGrid);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.map((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  // getCustomerDocumentData() {
  //   this.getTableColumn();
  //   this.entityService.getCommentByCustId(this.custId).subscribe(result => {
  //     this.dataGrid = result.dataList;
  //     this.refreshDataSource(this.dataGrid);
  //   });
  // }
}

export class filters {
  filterColumn: string
  filterCondition: string
  filterDataType: string
  filterOperator: string
  filterValue: string
}
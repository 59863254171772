import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommentModel } from '../model/comment-model';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractBaseAddUpdateComponent } from '../../../../../components/base/abstract-base-add-update-component';
import { CommonStatusService } from '../../../../../services/common_status/common-status.service';
import { IBaseService } from '../../../../../services/ibase-service';
import { DataSharingServiceService } from '../../../../data-sharing-service.service';
import { CommentService } from '../service/comment.service';
import { ResponseCode } from '../../../../../core/constant/response-code';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-add-update-comment',
  templateUrl: './add-update-comment.component.html',
  styleUrls: ['./add-update-comment.component.scss']
})
export class AddUpdateCommentComponent extends AbstractBaseAddUpdateComponent<CommentModel> implements OnInit {
  @Input() custId: any;
  @Input() docId: any;
  public onSaved: EventEmitter<any> = new EventEmitter<any>();
  @Output() message: any = new EventEmitter();
  public entity: CommentModel = new CommentModel();
  public entity_id: any = null;
  form: FormGroup;
  showUpdate: boolean = false;
  url: string;
  public userList: any[] = [];
  public commentList: any[] = [];

  //public userList = new Array<any>();
  commonStatus: any = [{
    text: 'Select Status', value: ''
  }];
  constructor(
    private CommentService: CommentService,
    private CommonStatus: CommonStatusService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private genericDataSharingService: DataSharingServiceService,
    ) {
    super(toastrService, router);
  }

  ngOnInit() {
   // this.getCustomerDocumentData();
    let id = this.activeRoute.snapshot.paramMap.get('id');
    // if (id) {
    //   this.isUpdateComponent = true;
    //   this.getById(Number(id));
    // }
    // this.CommonStatus.getCommonList().subscribe((res) => {
    //   this.commonStatus = res.dataList
    // })
    this.setValidation();
  } 
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  getService(): IBaseService<CommentModel> {
    return this.CommentService;
  }

  setData(data: CommentModel) {
    this.entity = data;
  }

  getData(): CommentModel {
    return this.entity;
  }

  public getModuleHomeUrl(): string {

    //this.url = '/network-management/serviceArea'
    return;
  }

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): CommentModel {
    return new CommentModel();
  }

  public getFormGroup(): FormGroup {
    return this.form;
  }

  public getId(): number {
    return this.entity.id;
  }

  get f() {
    return this.form.controls;
  }

  setValidation() {
    this.form = new FormGroup({
      text: new FormControl('', [Validators.required]),
    });
  }

  formSubmit() {
    this.submitted=true
    if (this.form.valid) {
      const text = this.form.get('text').value;
      const self = this;
      alertify.confirm(`Are you sure you want to save this Comment record?`, function () {
        self.saveCommentOnDb(self.custId, text);
      });
    } else {
      this.toastrService.error("Please fill all the details!");
    }
  }

  saveCommentOnDb(custId: any, text: string) {
    const data = {
      custId: custId,
      text: text 
    };

    this.getService()
      .save(data)
      .subscribe(
        (response) => {
          if (response.responseCode !== ResponseCode.OK) {
            this.onResponseError(response.responseMessage);
            return;
          }
          this.responseData = response;
          this.onSavedCallback.emit(response.data);
          this.onSaved.emit(false)
          this.onSaveSuccess();
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }

  // formSubmit() {
  //   if (this.validateForm()) {
  //     alertify.confirm(`Are you sure you want to save this Comment record?`,this.saveCommentOnDb(this.custId));
        
  //   }
  //   else {
  //     this.toastrService.error("Please fill all the details!");
  //   }
  // }

  // saveCommentOnDb(data: any){
  //     this.getService()
  //       .save(data)
  //       .subscribe(
  //         (response) => {
  //           if (response.responseCode != ResponseCode.OK) {
  //             this.onResponseError(response.responseMessage);
  //             return;
  //           }
  //           this.responseData=response;
  //           this.onSavedCallback.emit(response.data);
  //           this.onSaveSuccess();
  //         },
  //         (error) => {
  //           this.onResponseError(error.message);
  //         }
  //       );
  // }
  updateCommentOnDb(){

  }

  closeDialog(){
    //
  }

getGenericDataService(){
return this.genericDataSharingService
}

onCancel() {
  this.showUpdate = false;
  this.message.emit("Close");
}



}
